


















import { Vue, Component, Prop } from "vue-property-decorator";
import { SystemSettingInfoProperties } from "./SystemSettingInfoView.vue";

@Component({})
export default class SystemSettingSessionClosingView extends Vue {
    @Prop() private properties: SystemSettingInfoProperties;

    public get systemSetting() {
        return this.properties.systemSetting;
    }
    public get sessionClosing() {
        return this.systemSetting.sessionClosing;
    }
}
